import flagDA from '../assets/flag-da.svg';
import flagEN from '../assets/flag-en.svg';
import routesDaDK from '../json/routes/routes_da.json';
import { Localized } from '../types/cms/generalTypes';
import { Language, LanguageKey, ResourceLanguagePath } from '../types/common';

const PATHS_ROUTES_REPLACE = '[$1]';

export const DANSK: Language = {
  key: 'da-DK',
  description: 'Dansk',
  flag: flagDA,
};

export const ENGLISH: Language = {
  key: 'en-DK',
  description: 'English',
  flag: flagEN,
};

export const LANGUAGES = [DANSK, ENGLISH];

export function findLanguageByKey(key: LanguageKey) {
  if (key === 'default') {
    return DANSK;
  }
  return LANGUAGES.find((language) => language.key === key);
}

export function getRoute(englishPath: string, locale: LanguageKey, languagePaths: ResourceLanguagePath[] = []) {
  const allRoutes = [routesDaDK];
  const routesForLocale = allRoutes.find((routeCollection) => routeCollection.locale === locale);
  const englishLanguagePath = languagePaths.find((languagePath) => languagePath.key === 'en-DK');
  const destinationLanguagePath = languagePaths.find((languagePath) => languagePath.key === locale);
  const key = englishLanguagePath?.query?.key || 'slug';
  const pattern = new RegExp(`[\:\[](${key})\]?`);
  const foundRoute = routesForLocale?.routes.find((route) => route.destination.replace(pattern, PATHS_ROUTES_REPLACE) === englishPath);
  const finalRoute = (foundRoute?.source || englishPath).replace(pattern, destinationLanguagePath?.query?.value || PATHS_ROUTES_REPLACE);
  return finalRoute.startsWith('/') ? finalRoute : `/${finalRoute}`;
}

export function splitLocale(locale: LanguageKey): { language: string; country: string } {
  if (locale === 'default') {
    return splitLocale(DANSK.key);
  }
  const [language, country] = locale.split('-');
  return { language, country };
}

export function getDefaultLanguagePaths(id: string, key: string = 'slug'): ResourceLanguagePath[] {
  return [
    { key: 'da-DK', query: { key, value: id } },
    { key: 'en-DK', query: { key, value: id } },
  ];
}

export function createLanguagePaths(resource: Localized<{ Slug?: string }>, locale: LanguageKey): ResourceLanguagePath[] {
  if (!resource.Slug) {
    return [
      { key: locale as LanguageKey },
      ...resource.localizations.data.map((page) => ({
        key: page.attributes.locale,
      })),
    ];
  }
  return [
    { key: locale as LanguageKey, query: { key: 'slug', value: resource.Slug } },
    ...resource.localizations.data.map((page) => ({
      key: page.attributes.locale,
      ...(page.attributes.Slug
        ? {
            query: {
              key: 'slug',
              value: page.attributes.Slug,
            },
          }
        : {}),
    })),
  ];
}
