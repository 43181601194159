export const CMS_BASE_URL = process.env.NEXT_PUBLIC_CMS_BASE_URL || 'http://localhost:1337/api';
export const API_BASE_URL = process.env.NEXT_PUBLIC_API_BASE_URL || 'http://localhost:8080/api';
export const SPA_BASE_URL = process.env.NEXT_PUBLIC_SPA_BASE_URL || 'http://localhost:3000';
export const EMBEDDED_BASE_URL = process.env.NEXT_PUBLIC_EMBEDDED_BASE_URL || 'http://localhost:3001';
export const TENANT_API_BASE_URL = process.env.NEXT_PUBLIC_TENANT_API_BASE_URL || 'http://localhost:8000/tenant-api';
export const SITE_URL = process.env.NEXT_PUBLIC_SITE_URL || 'http://localhost:3030';
export const VIAMAP_API_URL = process.env.NEXT_PUBLIC_VIAMAP_API_URL || 'https://nybygget.poi.viamap.net/v1';
export const VIAMAP_ACCESS_TOKEN =
  process.env.NEXT_PUBLIC_VIAMAP_ACCESS_TOKEN ||
  'eyJkcGZ4IjogIm55YnlnZ2V0IiwgInZlciI6ICIyIiwgInByaXZzIjogInJnZGpoYzcvZGl0RUEzQlpyMVowcjBGMGs2QnRXcVFjT1V5a0IveTZTVXBKdjJRYmd5WGV0Zz09In0.D8RBpaejfHJXWY/EYL1DlMjIFPP7FGTyDqGxIWMjjLPj8My0AbaoKdit1IO1LOQa7nonVAzabfxqpP57U6GWPw';
export const SKIP_BUILD_PROPERTY_GROUPS = process.env.NEXT_PUBLIC_SKIP_BUILD_PROPERTY_GROUPS || false;
export const SKIP_BUILD_PROPERTIES = process.env.NEXT_PUBLIC_SKIP_BUILD_PROPERTIES || false;
export const UNLEASH_PROXY_URL = process.env.NEXT_PUBLIC_UNLEASH_PROXY_URL || 'http://localhost:5008/proxy';
export const UNLEASH_PROXY_TOKEN = process.env.NEXT_PUBLIC_UNLEASH_PROXY_TOKEN || '1234';
export const UNLEASH_PROXY_APP_NAME = process.env.NEXT_PUBLIC_UNLEASH_PROXY_APP_NAME || 'dev';
export const UNLEASH_PROXY_REFRESH_INTERVAL = process.env.NEXT_PUBLIC_UNLEASH_PROXY_REFRESH_INTERVAL || 15;
export const RUDDERSTACK_URL = process.env.NEXT_PUBLIC_RUDDERSTACK_URL || 'https://hosted.rudderlabs.com';
export const RUDDERSTACK_KEY = process.env.NEXT_PUBLIC_RUDDERSTACK_KEY || '2BD4lzpVSmuGt5aCuHVSTrWzcoJ';
export const UPDATE_FROM_STRAPI_KEY = process.env.NEXT_PUBLIC_UPDATE_FROM_STRAPI_KEY || 'xKTDZ8efW6CRhvIEF8RcvSjF';
export const UPDATE_FROM_API_KEY = process.env.NEXT_PUBLIC_UPDATE_FROM_API_KEY || '3KGe5evSLZD0q4v7pQGFmyQG';
export type NEXT_PUBLIC_ENVIRONMENT = 'dev' | 'prod' | 'test';
export const NEXT_PUBLIC_ENVIRONMENT: NEXT_PUBLIC_ENVIRONMENT = (process.env.NEXT_PUBLIC_ENVIRONMENT as NEXT_PUBLIC_ENVIRONMENT) || 'dev';

export const PROPSTEP = 'propstep';
export const HANDLED_BY_BASIC = 'basic';
export const HANDLED_BY_STARTER = 'external';
export const HANDLED_BY_IN_HOUSE = 'selfService';

export const PROPERTY_AVAILABLE = 1;
export const PROPERTY_RESERVED = 2;
export const PROPERTY_RENTED = 3;

export enum PropertyStatus {
  UNPUBLISHED = 1,
  SHAREABLE = 2,
  PUBLISHED = 3,
  WAITING_LIST = 4,
}

export const PROPERTY_TO_RENT = 1;
export const PROPERTY_FOR_SALE = 2;

export const PROPERTY_TYPE_APARTMENT = 1;
export const PROPERTY_TYPE_TOWNHOUSE = 2;
export const PROPERTY_TYPE_HOUSE = 3;

export const SENIOR_HOUSING_TAG = 'DK:type:senior';
export const STUDENT_HOUSING_TAG = 'DK:type:student';

export const OPEN_GRAPH_DEFAULT_WIDTH = 1200;
export const OPEN_GRAPH_DEFAULT_HEIGHT = 630;

export const DIGIT_PATTERN = /^\d*$/;

export const ADMIN_ROLE_NAME = 1;
export const COMPANY_ADMIN_ROLE_NAME = 2;
export const COMPANY_PROJECT_MANAGER_ROLE_NAME = 3;
export const COMPANY_PROJECT_EDITOR_ROLE_NAME = 4;

export const APPLICATION_STATUS_ACTIVE = 1;
export const APPLICATION_STATUS_OFFERED = 3;
export const APPLICATION_STATUS_DONE = 4;
export const APPLICATION_STATUS_REJECTED = 5;
export const APPLICATION_STATUS_CANCELLED = 6;
export const APPLICATION_STATUS_EMAIL_ONLY = 7;
export const APPLICATION_STATUS_TERMINATED = 8;

export const DEFAULT_PHONE_MAX_LENGTH = 15;

export const TRUSTED_DOMAINS = [
  new URL(SITE_URL).hostname,
  new URL(SPA_BASE_URL).hostname,
  'test.newsec.propstep.com',
  'test-app.newsec.propstep.com',
];

export const SPONSORED_CATEGORY = ['sponsoreret', 'sponsored'];

/** All custom query parameter keys recognized within this application domain. */
export const enum QueryParamKeys {
  /** Values: `login`, `logout`  */
  Auth = 'auth',
  /** Used for external URLs, i.e., URLs outside this application domain. */
  RedirectUrl = 'redirectUrl',
}

/** All custom query parameter values recognized within this application. domain */

export const enum QueryParamValues {
  /** Used for displaying the login-modal. */
  Login = 'login',
  /** Used for preventing the login-modal from displaying in some scenarios.  */
  Logout = 'logout',
}

/** All possible query parameter key-value combinations.
 * @usage Prepend with a `?` or `&`.
 */
export const enum QueryParams {
  AuthLogin = QueryParamKeys.Auth + '=' + QueryParamValues.Login,
  AuthLogout = QueryParamKeys.Auth + '=' + QueryParamValues.Logout,
}

export const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID || 'GTM-N6Q3MM4C';
